export const ABOUT_IT_GALAXY = "footer.about";

export const HIRE_FREELANCERS_ARRAY = [
  {value : "Développeurs Web", url: 'https://itgalaxy.io/developpeurs-web'},
  {value : "Développeurs Wordpress", url: 'https://itgalaxy.io/developpeurs-wordpress'},
  {value : "Marketing & Seo",url: 'https://itgalaxy.io/developpeurs-seo'},
  {value : "Développeurs Backend",url: 'https://itgalaxy.io/developpeurs-backend'},
  {value : "DevOps",url: 'https://itgalaxy.io/devops'},
  {value : "Développeurs application Mobile",url: 'https://itgalaxy.io/developpeurs-mobile'},
  {value : "Désigners",url: 'https://itgalaxy.io/designer'},
  {value : "Développeurs Shopify",url: 'https://itgalaxy.io/developpeurs-shopify'},
  {value : "Infogérance Cloud AWS",url: 'https://aws-mastery.co'},
  {value : "Formation AWs",url: 'https://aws-mastery.co/formation-aws'},

];

export const PROJECTS_ARRAY = [
  {value : "Amélioration des sites Shopify",url: 'https://itgalaxy.io/missions-shopify'},
  {value : "Design un site Wordpress",url: 'https://itgalaxy.io/missions-wordpress'},
  {value : "Refonte site web avec React",url: 'https://itgalaxy.io/missions-web'},
  {value :  "Mise en place d'une application mobile avec Flutter",url: 'https://itgalaxy.io/missions-mobile'},
  {value :  "Création d'un system de facturation avec nodejs",url: 'https://itgalaxy.io/developpeurs-backend'},
  {value : "Migration sur AWS Services",url: 'https://itgalaxy.io/missions-aws'},
  {value : "Devenir le premier sur Google",url: 'https://itgalaxy.io/missions-seo'},
  {value : "Mise en place des Tests automatisés",url: 'https://itgalaxy.io/missions-testeurs'},
];


export const COMPANY_ARRAY = [
  {value : "Serveurs Ubuntu",url: 'https://itgalaxy.io/marketplaceubuntu'},
  {value : "Serveurs RedHat",url: 'https://itgalaxy.io/marketplaceredhat'},
  {value : "Serveurs Debian",url: 'https://itgalaxy.io/marketplacedebian'},
  {value : "Base de donnée Postgres",url: 'https://itgalaxy.io/marketplacepostgres'},
  {value : "Base de donnée Mysql",url: 'https://itgalaxy.io/marketplacemysql'},
  {value : "Base de donnée MongoDb",url: 'https://itgalaxy.io/marketplacemongodb'},

];

export const FORMATION_ARRAY = [
  {value : "Formation kubernetes",url: 'https://formations-k8s.itgalaxy.io'},
  {value : "Formation AWS",url: 'https://formations-aws.itgalaxy.io'},
  {value : "Formation React",url: 'https://formations-developpeur-frontend.itgalaxy.io/react-vs-svelte4-comparison/'},
  {value : "Formation Terraform",url: 'https://formations-terraform.itgalaxy.io/'},
  {value : "Formation angular",url: 'https://formations-developpeur-frontend.itgalaxy.io/angular-renaissance-vs-marko-comparison/index.html'},
  {value : "Formation Vue",url: 'https://formations-developpeur-frontend.itgalaxy.io/vue3-vs-mithril-comparison/'},
  {value : "Formation Gitlab",url: 'https://formations-gitlab.itgalaxy.io/'},
  {value : "Formation Docker",url: 'https://formations-docker.itgalaxy.io/'},
  {value : "Formation S3 & Email",url: 'https://itgalaxy.io/blog/free-mail-entreprise'},
  {value : "Formation Elasticsearch Kibana",url: 'https://itgalaxy.io/formations/monitoring-site-web-elk/commentmonitorersiteweb'},
];


export const CONTACT_ARRAY = [
  {value : "Plateform Freelance 2025",url: 'https://itgalaxy.io/blog/plateformes-freelance-france-2025'},
  {value : "Creation d'un site Web gratuitement",url: 'https://itgalaxy.io/blog/free-web-site-guide'},
  {value : "Creation d'un site Shopify",url: 'https://itgalaxy.io/missions-shopify'},
  {value : "Optimisation SEO pour avoir plus de client",url: 'https://itgalaxy.io/missions-seo'},
  {value : "Comment monitorer un site web",url: 'https://itgalaxy.io/formations/monitoring-site-web-elk/commentmonitorersiteweb'},
  {value : "Analyse et identification des problémes sur votre site web",url: 'https://itgalaxy.io/formations/monitoring-site-web-elk/commentmonitorersiteweb'},

];

export const Q1 = [
  {value : "comment creer une application mobile ?",url: 'https://itgalaxy.io/formations/creer-une-application-mobile/guideEntrepreneur'},
  {value : "Command Checkout in git ?",url: 'https://itgalaxy.io/formations/git/checkout'},
  {value : "Comment git checkout to commit ?",url: 'https://itgalaxy.io/formations/git/checkout'}, 
  {value : "supprimer une branche git",url: 'https://itgalaxy.io/formations/git/delete'},
  {value : "dockercoin",url: 'https://formations-k8s.itgalaxy.io/deploiement-application-dockercoins-/'},
  {value : "kubernetes c est quoi",url: 'https://formations-k8s.itgalaxy.io/introduction-kubernetes/'},
  {value : "architecture kubernetes",url: 'https://formations-k8s.itgalaxy.io/introduction-aux-objets-kubernetes/'},
  {value : "what is gitlab ?",url: 'https://itgalaxy.io/formations/gitlab/whatsgitlab'},
  {value : "Installer Gitlab Runner ",url: 'https://itgalaxy.io/formations/gitlab/gitlabrunners'},
  {value : "CI/CD",url: 'https://itgalaxy.io/formations/gitlab/gitlabcicd'},
  {value : ".gitlab-ci.yml exemples",url: 'https://itgalaxy.io/formations/gitlab/gitlabExp'},
  {value : "svelte 5 vs solid",url: 'https://formations-developpeur-frontend.itgalaxy.io/svelte5-vs-solidjs-comparison/'},
  {value : "svelte vs lit",url: 'https://formations-developpeur-frontend.itgalaxy.io/svelte5-vs-lit-comparison/'},
  {value : "solidjs vs qwik",url: 'https://formations-developpeur-frontend.itgalaxy.io/svelte5-vs-angular-comparison/'},
  {value : "alpine vs vue",url: 'https://formations-developpeur-frontend.itgalaxy.io/vue2-vs-alpine-comparison/'}
]

export const Q2 = [
  {value : "Quelles sont les frameWorks Wordpress?", url: 'https://itgalaxy.io/formations/awesome-frameworks/wordpress'},
  {value : "Quelles sont les frameWorks php?",   url: 'https://itgalaxy.io/formations/awesome-frameworks/css'},
  {value :"Quelles sont les frameWorks css?",    url: 'https://itgalaxy.io/formations/awesome-frameworks/php'},
  {value : "Quelles sont les frameWorks react?", url: 'https://itgalaxy.io/formations/awesome-frameworks/react'},
  {value : "Quelles sont les frameWorks nodejs?", url: 'https://itgalaxy.io/formations/awesome-frameworks/nodejs'},
  {value : "Quelles sont les frameWorks python?", url: 'https://itgalaxy.io/formations/awesome-frameworks/python'},
  {value :"Quelles sont les frameWorks flutter?" , url: 'https://itgalaxy.io/formations/awesome-frameworks/flutter'},
];

export const DEVOPS_SKILLS = [
  "Pipelines CI/CD",
  "Docker",
  "Kubernetes",
  "Terraform",
  "Ansible",
  "Cloud AWS",
  "Monitoring (Prometheus, Grafana)",
  "Git/GitHub/GitLab",
];

export const DEVOPS_PROJECTS = [
  "Migration vers Kubernetes : Migration d'une application monolithique vers une architecture microservices en utilisant Kubernetes.",
  "Pipeline CI/CD pour une plateforme e-commerce : Conception et mise en œuvre d'un pipeline CI/CD avec Jenkins et GitLab.",
  "Infrastructure as Code avec Terraform : Automatisation de la provision d'infrastructure cloud sur AWS avec Terraform.",
];

export const DEVOPS_FORMATIONS = [
  "Maîtrise de Docker",
  "Kubernetes pour les débutants",
  "Plongée approfondie dans Terraform",
];

export const DEVOPS_BLOGS = [
  "Introduction aux pipelines CI/CD (John Doe, 15/09/2023)",
  "Débuter avec Kubernetes (Jane Smith, 20/08/2023)",
  "Bonnes pratiques pour l'Infrastructure as Code (Alice Johnson, 10/07/2023)",
];

export const DEVOPS_Q1 = [
  "Qu'est-ce que CI/CD ? - CI/CD signifie Intégration Continue et Déploiement Continu. C'est une méthode pour livrer fréquemment des applications aux clients en automatisant les étapes du développement.",
  "Qu'est-ce que Docker ? - Docker est une plateforme qui permet aux développeurs d'automatiser le déploiement, la mise à l'échelle et la gestion des applications dans des conteneurs légers et portables.",
  "Qu'est-ce que Kubernetes ? - Kubernetes est une plateforme open-source d'orchestration de conteneurs qui automatise le déploiement, la mise à l'échelle et la gestion des applications conteneurisées.",
];

export const DEVOPS_Q2 = [
  "Qu'est-ce que Terraform ? - Terraform est un outil open-source d'Infrastructure as Code (IaC) qui permet de définir et de provisionner l'infrastructure en utilisant un langage de configuration déclaratif.",
  "Qu'est-ce qu'Ansible ? - Ansible est un outil open-source d'automatisation utilisé pour la gestion de configuration, le déploiement d'applications et l'automatisation des tâches.",
  "Qu'est-ce que Prometheus ? - Prometheus est une boîte à outils open-source de surveillance et d'alerte conçue pour la fiabilité et l'évolutivité.",
];


export const DESIGN_SKILLS = [
  "Conception d'interfaces utilisateur (UI)",
  "Expérience utilisateur (UX)",
  "Prototypage avec Figma",
  "Design graphique avec Adobe Photoshop",
  "Illustration vectorielle avec Adobe Illustrator",
  "Animation avec After Effects",
  "Design responsive",
  "Gestion des couleurs et typographie",
];

export const DESIGN_PROJECTS = [
  "Refonte de l'interface utilisateur d'une application mobile : Conception d'une interface moderne et intuitive pour une application de fitness.",
  "Création d'un site web responsive : Design et prototypage d'un site web adaptatif pour une entreprise locale.",
  "Design d'une campagne publicitaire : Conception graphique et visuelle pour une campagne de marketing digital.",
];
export const DESIGN_FORMATIONS = [
  "Maîtrise de Figma (Udemy, 8 heures)",
  "Principes de base de l'UX/UI (Coursera, 12 heures)",
  "Design graphique avancé avec Adobe Creative Suite (LinkedIn Learning, 10 heures)",
];

export const DESIGN_BLOGS = [
  "Les tendances du design en 2023 (Jean Dupont, 15/09/2023)",
  "Comment créer des interfaces utilisateur accessibles (Marie Martin, 20/08/2023)",
  "Les bases de la typographie pour les designers (Lucie Leroy, 10/07/2023)",
];

export const DESIGN_Q1 = [
  "Qu'est-ce que l'UI design ? - L'UI design (User Interface Design) se concentre sur la conception des interfaces utilisateur, en veillant à ce qu'elles soient esthétiques et faciles à utiliser.",
  "Qu'est-ce que l'UX design ? - L'UX design (User Experience Design) se concentre sur l'expérience globale de l'utilisateur, en s'assurant que le produit est intuitif et agréable à utiliser.",
  "Qu'est-ce que Figma ? - Figma est un outil de conception d'interfaces utilisateur basé sur le cloud, utilisé pour le prototypage et la collaboration en temps réel.",
];
export const DESIGN_Q2 = [
  "Qu'est-ce que le design responsive ? - Le design responsive est une approche de conception qui permet aux sites web de s'adapter à différentes tailles d'écran (ordinateur, tablette, mobile).",
  "Qu'est-ce que la typographie ? - La typographie est l'art et la technique d'arranger le texte pour le rendre lisible et esthétique.",
  "Qu'est-ce qu'un prototype ? - Un prototype est une version préliminaire d'un design, utilisée pour tester et valider les concepts avant la mise en production.",
];

export const BACKEND_SKILLS = [
  "Développement d'API RESTful",
  "Gestion de bases de données SQL et NoSQL",
  "Framework Django (Python)",
  "Framework Spring Boot (Java)",
  "Node.js et Express (JavaScript)",
  "Authentification et autorisation (JWT, OAuth)",
  "Tests unitaires et d'intégration",
  "Déploiement avec Docker et Kubernetes",
];

export const BACKEND_PROJECTS = [
  "Création d'une API RESTful pour une application e-commerce : Développement d'une API pour gérer les produits, les commandes et les utilisateurs.",
  "Système de gestion de contenu (CMS) avec Django : Conception et développement d'un CMS personnalisé pour un client.",
  "Microservices avec Spring Boot : Développement d'une architecture microservices pour une application de réservation en ligne.",
];

export const BACKEND_FORMATIONS = [
  "Maîtrise de Django (Udemy, 12 heures)",
  "Développement d'API avec Node.js (Coursera, 15 heures)",
  "Spring Boot pour les débutants (Pluralsight, 10 heures)",
];

export const BACKEND_BLOGS = [
  "Les bonnes pratiques pour développer des API RESTful (Jean Dupont, 15/09/2023)",
  "Introduction à l'authentification avec JWT (Marie Martin, 20/08/2023)",
  "Déploiement d'applications avec Docker et Kubernetes (Lucie Leroy, 10/07/2023)",
];

export const BACKEND_Q1 = [
  "Qu'est-ce qu'une API RESTful ? - Une API RESTful est une interface de programmation qui suit les principes REST (Representational State Transfer) pour permettre la communication entre des systèmes.",
  "Qu'est-ce que Django ? - Django est un framework web open-source en Python, conçu pour le développement rapide et propre d'applications web.",
  "Qu'est-ce que Node.js ? - Node.js est un environnement d'exécution JavaScript basé sur le moteur V8 de Chrome, utilisé pour développer des applications backend.",
];
export const BACKEND_Q2 = [
  "Qu'est-ce qu'une base de données NoSQL ? - Une base de données NoSQL est un système de gestion de base de données qui stocke et récupère des données de manière non relationnelle.",
  "Qu'est-ce que Spring Boot ? - Spring Boot est un framework Java qui simplifie le développement d'applications en fournissant des configurations par défaut et des outils intégrés.",
  "Qu'est-ce que JWT ? - JWT (JSON Web Token) est un standard ouvert pour la création de jetons d'authentification sécurisés.",
];

export const FRONTEND_SKILLS = [
  'HTML',
  'CSS',
  'JavaScript',
  'React',
  'Vue.js',
  'Angular',
  'Sass',
  'TypeScript',
  'Responsive Design'
];

export const FRONTEND_PROJECTS = [
  'Site de portfolio',
  'Plateforme e-commerce',
  'Application de blog',
  'Tableau de bord des réseaux sociaux',
  'Application météo',
  'Application de liste de tâches',
  'Galerie de photos interactive',
  'Jeu en ligne simple'
];

export const FRONTEND_FORMATIONS = [
  'Udemy : Modern React with Redux',
  'Coursera : Web Design for Everybody',
  'FreeCodeCamp : Front End Development Libraries',
  'Pluralsight : CSS Grids and Flexbox for Responsive Web Design',
  'LinkedIn Learning : JavaScript Avancé',
  'Codecademy : Introduction à Vue.js',
  'OpenClassrooms : Développez des applications web avec Angular',
  'Khan Academy : Design Adaptatif'
];

export const FRONTEND_BLOGS = [
  'CSS-Tricks',
  'Smashing Magazine',
  'A List Apart',
  'JavaScript Weekly',
  'Scotch.io',
  'Codrops',
  'SitePoint',
  'FrontEnd Focus'
];

export const FRONTEND_Q1 = [
  'Quelle est la différence entre les éléments block et inline ?',
  'Comment fonctionne le modèle de boîte CSS ?',
  'Quelles sont les nouveautés de ES6 ?',
  'Comment gérer les conflits de style en CSS ?',
  'Qu’est-ce que le DOM et comment interagir avec lui ?',
  'Comment créer une mise en page responsive?',
  'Quels sont les avantages de TypeScript en développement frontend ?'
];

export const FRONTEND_Q2 = [
  'Comment optimiser les ressources d’un site web pour des temps de chargement plus rapides ?',
  'Quel est le but d’une bibliothèque de gestion d’état dans React ?',
  'Comment utiliser l’API Fetch pour effectuer des requêtes HTTP ?',
  'Qu’est-ce que le Virtual DOM et comment fonctionne-t-il?',
  'Comment gérer les erreurs dans JavaScript ?',
  'Quelles sont les meilleures pratiques pour l’accessibilité web ?',
  'Comment mettre en œuvre le lazy loading des images ?'
];

export const MOBILE_SKILLS = [
  'Java',
  'Kotlin',
  'Swift',
  'React Native',
  'Flutter',
  'Xamarin',
  'Objective-C',
  'Ionic'
];

export const MOBILE_PROJECTS = [
  'Application de suivi de la condition physique',
  'Plateforme de réseau social mobile',
  'Application de gestion de tâches',
  'Jeu mobile interactif',
  'Application de chat en temps réel',
  'Service de streaming musical sur mobile',
  'Application de réservation de voyages',
  'Application de banque mobile'
];

export const MOBILE_FORMATIONS = [
  'Udemy : Développement Android avec Kotlin',
  'Coursera : Développement iOS pour débutants',
  'Pluralsight : Maîtriser Flutter et Dart',
  'LinkedIn Learning : Développement avec React Native',
  'Codecademy : Introduction à Swift',
  'edX : Développement d’applications mobiles multiplateformes',
  'OpenClassrooms : Concevez des applications mobiles avec Xamarin',
  'Khan Academy : Programmation Java pour Android'
];

export const MOBILE_BLOGS = [
  'Android Developers Blog',
  'Ray Wenderlich',
  'iOS Dev Weekly',
  'Flutter Dev',
  'React Native Blog',
  'AppCoda',
  'Mobile Dev Memo',
  'Xamarin Blog'
];

export const MOBILE_Q1 = [
  'Quelle est la différence entre Android et iOS en termes de développement ?',
  'Quels sont les avantages de React Native pour le développement mobile ?',
  'Comment structurer un projet Flutter ?',
  'Comment gérer les permissions utilisateur dans une application mobile ?',
  'Quelles sont les bonnes pratiques pour le design UI/UX mobile ?',
  'Comment optimiser les performances d’une application mobile ?',
  'Quels sont les principaux défis du développement multiplateforme ?'
];

export const MOBILE_Q2 = [
  'Comment intégrer un service de notification push dans une application mobile ?',
  'Quelles sont les stratégies de gestion de la mémoire dans les applications mobiles ?',
  'Comment tester des applications mobiles sur différents appareils ?',
  'Qu’est-ce que la publication d’applications sur l’App Store implique ?',
  'Comment utiliser les API RESTful dans une application mobile ?',
  'Quels outils de CI/CD recommandez-vous pour les applications mobiles ?',
  'Comment implémenter la sécurité dans le développement mobile ?'
];

export const SEO_SKILLS = [
  'Recherche de mots-clés',
  'Optimisation on-page',
  'Stratégie de contenu',
  'Analyse de backlinks',
  'SEO technique',
  'Optimisation du taux de conversion',
  'SEO local',
  'Analyse des performances SEO'
];

export const SEO_PROJECTS = [
  'Amélioration du classement organique d’un site e-commerce',
  'Développement d’une stratégie SEO pour un blog',
  'Audit SEO complet d’un site web',
  'Campagne de création de backlinks',
  'Optimisation SEO pour un site multilingue',
  'SEO pour une application mobile',
  'Analyse concurrentielle SEO',
  'Mise en œuvre de SEO local pour une entreprise'
];

export const SEO_FORMATIONS = [
  'Udemy : SEO pour débutants',
  'Coursera : Optimisation des moteurs de recherche',
  'Moz Academy : SEO Essentials',
  'LinkedIn Learning : SEO Techniques avancées',
  'Semrush Academy : SEO Fundamentals',
  'Google Garage : Fundamentals of Digital Marketing',
  'Yoast Academy : Search Engine Optimization',
  'HubSpot Academy : SEO Training Course'
];

export const SEO_BLOGS = [
  'Moz Blog',
  'Search Engine Land',
  'Neil Patel Blog',
  'Ahrefs Blog',
  'Backlinko',
  'SEMrush Blog',
  'SEO Roundtable',
  'Yoast SEO Blog'
];

export const SEO_Q1 = [
  'Quelles sont les meilleures pratiques pour la recherche de mots-clés ?',
  'Comment améliorer le SEO on-page d’un site web ?',
  'Quels facteurs influencent le classement d’un site sur Google ?',
  'Comment réaliser un audit SEO efficace ?',
  'Quels sont les outils SEO indispensables ?',
  'Comment optimiser les images pour le SEO ?',
  'Quelle est l’importance des balises meta pour le référencement ?'
];

export const SEO_Q2 = [
  'Quelles stratégies recommandez-vous pour obtenir des backlinks de qualité ?',
  'Comment mesure-t-on le succès d’une campagne SEO ?',
  'Quels sont les défis du SEO international ?',
  'Comment intégrer le SEO dans une stratégie de marketing numérique ?',
  'Quelle est l’importance du SEO technique et comment le mettre en œuvre ?',
  'Comment le SEO local peut-il bénéficier aux petites entreprises ?',
  'Quels sont les impacts des mises à jour des algorithmes Google sur le SEO ?'
];

export const SHOPIFY_SKILLS = [
  'Personnalisation de thèmes',
  'Développement d’applications Shopify',
  'Liquid (langage de modèle de Shopify)',
  'Gestion des produits et inventaire',
  'SEO pour Shopify',
  'Intégration de systèmes de paiement',
  'Optimisation de la vitesse du site',
  'Automatisation avec Shopify Flow'
];

export const SHOPIFY_PROJECTS = [
  'Création d’une boutique Shopify personnalisée',
  'Migration vers Shopify depuis une autre plateforme',
  'Développement d’une application Shopify pour automatiser les envois',
  'Optimisation SEO d’une boutique Shopify',
  'Intégration d’un système de paiement sécurisé',
  'Création d’un thème Shopify sur mesure',
  'Gestion des campagnes marketing avec Shopify',
  'Analyse des données de vente dans Shopify'
];

export const SHOPIFY_FORMATIONS = [
  'Shopify Compass : Les bases de Shopify',
  'Udemy : Shopify pour débutants',
  'LinkedIn Learning : Shopify Essential Training',
  'Coursera : Introduction à Shopify Development',
  'Skillshare : Designing Shopify Themes',
  'YouTube : Tutoriels de Shopify par Shopify Experts',
  'Shopify Partners : Académie pour les développeurs',
  'Codecademy : Utilisation du Liquid pour Shopify'
];

export const SHOPIFY_BLOGS = [
  'Shopify Blog',
  'Shopify Plus Blog',
  'Ecommerce Nation',
  'Oberlo Blog',
  'Practical Ecommerce',
  'Ecommerce CEO',
  'Shopify Partners Blog',
  'PageFly Blog'
];

export const SHOPIFY_Q1 = [
  'Quelles sont les meilleures pratiques pour personnaliser un thème Shopify ?',
  'Comment développer une application Shopify simple ?',
  'Quels avantages offre Shopify par rapport à d’autres plateformes e-commerce ?',
  'Comment optimiser une boutique Shopify pour le référencement ?',
  'Qu’est-ce que Liquid et comment l’utiliser dans Shopify ?',
  'Comment gérer efficacement l’inventaire sur Shopify ?',
  'Quels sont les conseils pour améliorer la vitesse d’un site Shopify ?'
];

export const SHOPIFY_Q2 = [
  'Quels plugins recommandez-vous pour une boutique Shopify ?',
  'Comment configurer les options d’expédition dans Shopify ?',
  'Quelles stratégies marketing sont efficaces sur Shopify ?',
  'Comment intégrer Shopify avec d’autres outils de gestion de données ?',
  'Comment sécuriser les transactions sur une boutique Shopify ?',
  'Comment utiliser les rapports et analyses dans Shopify pour améliorer les ventes ?',
  'Quels sont les défis de la migration vers Shopify et comment les surmonter ?'
];

export const WORDPRESS_SKILLS = [
  'Installation et configuration de WordPress',
  'Personnalisation de thèmes',
  'Développement de plugins',
  'SEO pour WordPress',
  'Sécurité et sauvegardes WordPress',
  'Optimisation des performances du site',
  'Utilisation de Gutenberg (éditeur de blocs)',
  'Gestion multisite WordPress'
];

export const WORDPRESS_PROJECTS = [
  'Création d’un blog professionnel',
  'Développement d’un site e-commerce avec WooCommerce',
  'Migration d’un site HTML vers WordPress',
  'Conception d’un thème WordPress personnalisé',
  'Développement d’un plugin pour gérer les événements',
  'Optimisation SEO d’un site WordPress',
  'Intégration de réseaux sociaux dans WordPress',
  'Mise en œuvre d’un système de membres sur WordPress'
];

export const WORDPRESS_FORMATIONS = [
  'Udemy : WordPress pour débutants',
  'Coursera : Construire un site Web avec WordPress',
  'LinkedIn Learning : WordPress Essentials',
  'WP101 : Tutoriels WordPress',
  'Skillshare : Création de thèmes WordPress',
  'Codecademy : Développement de plugins WordPress',
  'Yoast Academy : SEO pour WordPress',
  'Khan Academy : Conception Web avec WordPress'
];

export const WORDPRESS_BLOGS = [
  'WordPress.org Blog',
  'WPBeginner',
  'WP Tavern',
  'WPMU DEV Blog',
  'Elegant Themes Blog',
  'Yoast SEO Blog',
  'WP Mayor',
  'WooCommerce Blog'
];

export const WORDPRESS_Q1 = [
  'Comment choisir le bon thème WordPress pour votre site ?',
  'Quelles sont les meilleures pratiques SEO pour WordPress ?',
  'Comment sécuriser un site WordPress contre les attaques ?',
  'Comment créer un plugin WordPress de base ?',
  'Quelle est l’importance de l’hébergement dans WordPress ?',
  'Comment utiliser les widgets et les menus dans WordPress ?',
  'Qu’est-ce que Gutenberg et comment l’utiliser pour créer du contenu ?'
];

export const WORDPRESS_Q2 = [
  'Comment résoudre les problèmes de performance d’un site WordPress ?',
  'Quels sont les avantages de l’utilisation de WooCommerce avec WordPress ?',
  'Comment configurer des sauvegardes automatiques pour WordPress ?',
  'Quels sont les défis courants avec la gestion multisite WordPress ?',
  'Comment intégrer un CRM avec WordPress ?',
  'Comment tester un site WordPress avant sa mise en ligne ?',
  'Quelles extensions sont essentielles pour améliorer les fonctionnalités de WordPress ?'
];